var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-row',{staticClass:"w-100 mx-0"},[_c('b-col',{staticClass:"px-3 px-lg-5 py-3 py-lg-5 justify-content-center vh-100",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('img',{staticClass:"logo mx-4",attrs:{"src":require("@/assets/bithonor_logo_vertical.png"),"alt":"Logo de Bithonor","title":"Logo de Bithonor"}}),_c('b-row',{staticClass:"form mt-5 mx-0 w-100 px-4"},[_c('div',{staticClass:"title text-center text-md-start"},[_vm._v("Recuperación de Contraseña")]),_c('div',{staticClass:"subtitle mt-2 text-center text-md-start"},[_vm._v(" Ingresa a tu cuenta para crear una nueva contraseña ")]),_c('div',{staticClass:"form-group w-100 mt-5"},[_c('label',[_vm._v("Contraseña  "),_c('span',{staticClass:"fw-bold",class:_vm.$v.password.$error ? 'fc-red' : null},[_vm._v("*")])]),_c('div',{staticClass:"password-field w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.$model),expression:"$v.password.$model",modifiers:{"trim":true}}],staticClass:"password-field__input",attrs:{"type":_vm.showPassword ? 'text' : 'password'},domProps:{"value":(_vm.$v.password.$model)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"show-password",on:{"click":function($event){_vm.showPassword = !_vm.showPassword; _vm.showRepeatPassword = false;}}},[(_vm.showPassword)?_c('SvgIcon',{attrs:{"name":"icon-eye-off","styles":"show-password__icon"}}):_c('SvgIcon',{attrs:{"name":"icon-eye","styles":"show-password__icon"}})],1)]),_c('div',{staticClass:"form-group__invalid",style:({
                        visibility:
                                _vm.$v.password.$error
                                ? 'visible'
                                : 'hidden',
                    })},[_vm._v(" "+_vm._s(!_vm.$v.password.required ? 'Campo requerido' : 'Mínimo 8 caracteres, al menos 1 mayúscula y 1 número')+" ")])]),_c('div',{staticClass:"form-group w-100 mt-2"},[_c('label',[_vm._v("Confirma tu contraseña "),_c('span',{staticClass:"fw-bold",class:_vm.$v.repeatPassword.$error ? 'fc-red' : null},[_vm._v("*")])]),_c('div',{staticClass:"password-field w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.repeatPassword.$model),expression:"$v.repeatPassword.$model",modifiers:{"trim":true}}],staticClass:"password-field__input",attrs:{"type":_vm.showRepeatPassword ? 'text' : 'password'},domProps:{"value":(_vm.$v.repeatPassword.$model)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.repeatPassword, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"show-password",on:{"click":function($event){_vm.showRepeatPassword = !_vm.showRepeatPassword; _vm.showPassword = false;}}},[(_vm.showRepeatPassword)?_c('SvgIcon',{attrs:{"name":"icon-eye-off","styles":"show-password__icon"}}):_c('SvgIcon',{attrs:{"name":"icon-eye","styles":"show-password__icon"}})],1)]),_c('div',{staticClass:"form-group__invalid",style:({
                        visibility:
                                _vm.$v.repeatPassword.$error
                                ? 'visible'
                                : 'hidden',
                    })},[_vm._v(" "+_vm._s(!_vm.$v.repeatPassword.required ? 'Campo requerido' : 'Las contraseñas deben coincidir')+" ")])]),_c('div',{staticClass:"mt-3 align-items-center justify-content-center justify-content-md-start d-none d-lg-flex"},[_c('div',{staticClass:"button primary-button--2 mx-3 mx-lg-0",on:{"click":function($event){return _vm.submit()}}},[_c('b',{staticClass:"fc-white"},[_vm._v("Crear clave")])]),_c('div',{staticClass:"button secondary-button--1 mx-3",on:{"click":function($event){_vm.$router.push({name: 'SendEmail'}).catch();}}},[_vm._v("Cancelar")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"mt-3 w-100 d-flex d-lg-none mx-0 justify-content-between justify-content-sm-center justify-content-md-between"},[_c('b-col',{attrs:{"cols":"6","sm":"4","md":"6"}},[_c('button',{staticClass:"button primary-button--2 w-100",on:{"click":function($event){return _vm.submit()}}},[_c('b',{staticClass:"fc-white"},[_vm._v("Crear clave")])])]),_c('b-col',{attrs:{"cols":"6","sm":"4","md":"6"}},[_c('button',{staticClass:"button secondary-button--1 w-100",on:{"click":function($event){_vm.$router.push({name: 'SendEmail'}).catch();}}},[_vm._v(" Cancelar ")])])],1)],1)],1)],1),_c('b-col',{staticClass:"d-none d-md-block h-100 px-0",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('InfoSide',{attrs:{"title":"Recupera tu clave","subtitle":"Ingresa tus datos para restablecer tu contraseña y enviar dinero a Venezuela","button":_vm.registerButton,"image":"business-error","darkMode":true,"right":true}})],1),_c('Loader',{ref:"loader",attrs:{"viewMode":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }